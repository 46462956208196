import { RoutingRouteEnum } from '../../../routing/route.enum';
import { AppSidebarTypesNavigationItemsType } from './types';
import { I18nMessages } from '../../../i18n/messages';
import contactIcon from './CustomIcons/contact-us.svg';
import { userStoreHasPermission } from '../../../user/store/has-permission';
import { UserPermissionSlugEnum } from '../../../api/model/user/permission-slug.enum';
import { BadgeTypeMenuBased } from '../../../common/components/Badge/type.enum';
import isCharitableDonationStore from '../../../common/helpers/user/is-charitable-donation-store';
import UserStoreModule from '../../../user/store/module';
import hasActivatedPOS from '../../../common/helpers/user/has-pos';
import ticktingIcon from './CustomIcons/ticktingIcon.svg';
import ticktingIconhlighted from './CustomIcons/ticktingIconhlighted.svg';
import zidGrowth from './CustomIcons/zid-growth.svg';
import zidGrowthHlighted from './CustomIcons/zid-growth-hlighted.svg';
import hasMarketplaceConnect from '../../../common/helpers/user/has-marketplace-connect';
import { PaymentGatewayStoreModule } from '../../../payment-options/store/payment-gateway/payment-gateway-module';
import { PaymentOptionsTypesThirdPartyProvidersStatusEnum } from '../../../payment-options/types/other-payment-gateway.interface';

function isPermissionRequired(slug?: UserPermissionSlugEnum): boolean {
  return !userStoreHasPermission(slug);
}

function hideApp(): boolean {
  if (!UserStoreModule.data) return true;
  return !(!!UserStoreModule.data.store.one_signal_keys.app_id && !!UserStoreModule.data.store.one_signal_keys.app_key);
}

function analyticsUpgradeRequired(): boolean {
  return !UserStoreModule?.data?.store.subscription.policies.stats_dashboard ?? false;
}

function isZidPay(): boolean {
  if (PaymentGatewayStoreModule.zidpaystatus?.name_en != PaymentOptionsTypesThirdPartyProvidersStatusEnum.notIntegrated)
    return true;
  return false;
}

export function navigationItems(): AppSidebarTypesNavigationItemsType {
  return [
    {
      name: 'home',
      labelTranslation: I18nMessages['common.page_header.main'],
      icon: 'home_outline',
      route: RoutingRouteEnum.home,
    },
    {
      name: 'analytics',
      labelTranslation: I18nMessages['sidebar.navigation_section.reports.title2'],
      icon: 'analytics_outline',
      hide: true,
      subscriptionUpgradeRequired: analyticsUpgradeRequired(),
      href: '/analytics',
    },
    {
      name: 'analytics',
      labelTranslation: I18nMessages['sidebar.navigation_section.reports.title2'],
      icon: 'analytics_outline',
      subRoutes: [RoutingRouteEnum.analyticsV2, RoutingRouteEnum.analyticsReports],
      items: [
        {
          name: RoutingRouteEnum.analyticsV2,
          route: RoutingRouteEnum.analyticsV2,
          labelTranslation: I18nMessages['sidebar.navigation_section.reports.title2'],
        },
        {
          name: RoutingRouteEnum.liveAnalytics,
          route: RoutingRouteEnum.liveAnalytics,
          labelTranslation: I18nMessages['sidebar.navigation_section.live_reports.title'],
          tag: BadgeTypeMenuBased.live,
        },
        {
          name: RoutingRouteEnum.analyticsReports,
          route: RoutingRouteEnum.analyticsReports,
          labelTranslation: I18nMessages['common.reports'],
        },
        {
          name: RoutingRouteEnum.faiApp,
          route: RoutingRouteEnum.faiApp,
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.reports),
          labelTranslation: I18nMessages['sidebar.navigation_section.fai_app'],
        },
      ],
    },
    {
      labelTranslation: I18nMessages['sidebar.navigation_section.orders.title'],
      icon: 'basket_outline',
      subRoutes: ['orders', 'abandonedCarts'],
      items: [
        {
          name: RoutingRouteEnum.orders,
          labelTranslation: isCharitableDonationStore()
            ? I18nMessages['sidebar.navigation_section.donations_orders.title']
            : I18nMessages['sidebar.navigation_section.orders.title'],
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.orders),
          route: RoutingRouteEnum.orders,
        },
        {
          name: RoutingRouteEnum.abandonedCarts,
          route: RoutingRouteEnum.abandonedCarts,
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.abandoned_cart'],
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.abandonedCarts),
        },
      ],
    },
    {
      labelTranslation: I18nMessages['sidebar.navigation_section.products.title'],
      icon: 'product_outline',
      hide: isCharitableDonationStore(),
      subRoutes: [
        RoutingRouteEnum.products,
        RoutingRouteEnum.createProduct,
        RoutingRouteEnum.editProduct,
        RoutingRouteEnum.optionsLibrary,
        RoutingRouteEnum.productOption,
        RoutingRouteEnum.groupedProduct,
      ],
      items: [
        {
          name: RoutingRouteEnum.products,
          route: RoutingRouteEnum.products,
          labelTranslation: I18nMessages['sidebar.navigation_section.products.title'],
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.products),
          siblingRoutes: [
            RoutingRouteEnum.products,
            RoutingRouteEnum.createProduct,
            RoutingRouteEnum.editProduct,
            RoutingRouteEnum.groupedProduct,
          ],
        },
        {
          name: RoutingRouteEnum.optionsLibrary,
          route: RoutingRouteEnum.optionsLibrary,
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.optionsLibrary),
          labelTranslation: I18nMessages['product.options_library.title'],
          siblingRoutes: [RoutingRouteEnum.optionsLibrary, RoutingRouteEnum.productOption],
        },
      ],
    },
    {
      labelTranslation: I18nMessages['sidebar.navigation_section.donations.title'],
      icon: 'product_outline',
      permissionRequired: isPermissionRequired(UserPermissionSlugEnum.products),
      route: RoutingRouteEnum.donations,
      hide: !isCharitableDonationStore(),
    },
    {
      labelTranslation: I18nMessages['sidebar.navigation_section.marketing.title'],
      icon: 'megaphone_outline',
      subRoutes: [
        'coupons',
        'fosteringNgo',
        'discountRules',
        RoutingRouteEnum.bundleOffers,
        RoutingRouteEnum.smsCampaignsPricing,
        RoutingRouteEnum.smsCampaigns,
        RoutingRouteEnum.createSmsCampaign,
        RoutingRouteEnum.editSmsCampaign,
        RoutingRouteEnum.viewSmsCampaign,
        'seo',
        RoutingRouteEnum.affiliateList,
        RoutingRouteEnum.loyaltyProgram,
        RoutingRouteEnum.landingPages,
        RoutingRouteEnum.qitafProgram,
      ],
      items: [
        {
          name: RoutingRouteEnum.landingPages,
          route: RoutingRouteEnum.landingPages,
          // permissionRequired: isPermissionRequired(UserPermissionSlugEnum.landingPages),
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.landing_pages'],
          tag: BadgeTypeMenuBased.new,
        },

        {
          name: RoutingRouteEnum.qitafProgram,
          route: RoutingRouteEnum.qitafProgram,
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.qitaf'],
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.qitafProgram),
          tag: BadgeTypeMenuBased.new,
        },
        {
          name: RoutingRouteEnum.loyaltyProgram,
          route: RoutingRouteEnum.loyaltyProgram,
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.loyaltyProgram),
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.loyalty'],
          tag: BadgeTypeMenuBased.new,
        },
        {
          name: RoutingRouteEnum.coupons,
          route: RoutingRouteEnum.coupons,
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.coupons),
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.coupons'],
        },
        {
          name: 'discountRules',
          href: '/discounts',
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.discountRules),
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.automatic_discounts'],
        },
        {
          name: RoutingRouteEnum.fosteringNgo,
          route: RoutingRouteEnum.fosteringNgo,
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.fosteringNgo),
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.fostering_ngo'],
          hide: isCharitableDonationStore(),
        },
        {
          name: RoutingRouteEnum.bundleOffers,
          route: RoutingRouteEnum.bundleOffers,
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.bundleOffers),
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.bundle_offers'],
        },
        {
          name: RoutingRouteEnum.smsCampaignsPricing,
          route: RoutingRouteEnum.smsCampaignsPricing,
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.smsCampaignsPricing),
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.sms_campaigns'],
          hide: window.__INITIAL_STATE__.isAppWebViewUnderReview,
          siblingRoutes: [
            RoutingRouteEnum.smsCampaigns,
            RoutingRouteEnum.createSmsCampaign,
            RoutingRouteEnum.editSmsCampaign,
            RoutingRouteEnum.viewSmsCampaign,
          ],
        },
        {
          name: 'seo',
          href: '/seo',
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.seo),
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.seo'],
        },
        {
          name: RoutingRouteEnum.affiliateList,
          route: RoutingRouteEnum.affiliateList,
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.affiliateList),
          labelTranslation: I18nMessages['sidebar.navigation_section.marketing.affiliate'],
        },
      ],
    },
    {
      name: 'customers',
      labelTranslation: I18nMessages['sidebar.navigation_section.customer.title'],
      icon: 'customer_outline',
      permissionRequired: isPermissionRequired(UserPermissionSlugEnum.customers),
      route: RoutingRouteEnum.customers,
    },
    {
      name: 'catalogTheme',
      labelTranslation: I18nMessages['common.store.identity'],
      icon: 'color_palette_outline',
      permissionRequired: isPermissionRequired(UserPermissionSlugEnum.storeCustomization),
      route: RoutingRouteEnum.storeDesign,
    },
    {
      name: 'storeSettings',
      labelTranslation: I18nMessages['sidebar.navigation_section.settings.title'],

      icon: 'settings',
      href: '/account/settings',
    },
    {
      name: 'ticketing',
      route: RoutingRouteEnum.ticketing,
      labelTranslation: I18nMessages['sidebar.navigation_section.ticketing'],
      customIcon: ticktingIcon,
      customIconHighlighted: ticktingIconhlighted,
    },
    {
      name: 'ZidProducts',
      labelTranslation: I18nMessages['sidebar.navigation_section.zid_products.title'],
      isSplitted: true,
      isHeading: true,
    },
    {
      name: RoutingRouteEnum.zidpos,
      route: hasActivatedPOS() ? RoutingRouteEnum.zidpos : RoutingRouteEnum.zidposPreSubscription,
      icon: 'zidpos_ar',
      iconCustomWidth: '24px',
      iconCustomHeight: '12px',
      permissionRequired: isPermissionRequired(UserPermissionSlugEnum.zidpos),
      labelTranslation: I18nMessages['sidebar.navigation_section.zidpos'],
      tag: BadgeTypeMenuBased.new,
    },
    {
      name: RoutingRouteEnum.finance,
      route: RoutingRouteEnum.finance,
      subRoutes: [
        RoutingRouteEnum.financeMarketing,
        RoutingRouteEnum.financeActivation,
        RoutingRouteEnum.financeLanding,
      ],
      icon: 'zidfinance_ar',
      iconCustomWidth: '20px',
      iconCustomHeight: '18px',
      labelTranslation: I18nMessages['sidebar.navigation_section.finance'],
      tag: BadgeTypeMenuBased.new,
      hide: window.__INITIAL_STATE__.isAppWebViewUnderReview,
      permissionRequired: isPermissionRequired(UserPermissionSlugEnum.finance),
    },
    {
      name: RoutingRouteEnum.MerchantGrowth,
      route: RoutingRouteEnum.MerchantGrowth,
      customIcon: zidGrowth,
      customIconHighlighted: zidGrowthHlighted,
      labelTranslation: I18nMessages['merchant_growth.main_title'],
      tag: BadgeTypeMenuBased.new,
    },
    {
      name: RoutingRouteEnum.mazeed,
      route: RoutingRouteEnum.mazeed,
      subRoutes: [
        RoutingRouteEnum.mazeedSettings,
        RoutingRouteEnum.mazeedSettlementInvoice,
        RoutingRouteEnum.mazeedSettlements,
      ],
      tag: BadgeTypeMenuBased.fastActivation,
      icon: 'zid_mazeed_ar',
      iconCustomWidth: '16px',
      iconCustomHeight: '12px',
      permissionRequired: isPermissionRequired(UserPermissionSlugEnum.mazeed),
      labelTranslation: I18nMessages['sidebar.navigation_section.mazeed'],
    },
    {
      name: RoutingRouteEnum.zidShip,
      route: RoutingRouteEnum.zidShip,
      subRoutes: [
        RoutingRouteEnum.zidShip,
        RoutingRouteEnum.zidshipWallet,
        RoutingRouteEnum.zidshipShipments,
        RoutingRouteEnum.zidshipReports,
        RoutingRouteEnum.zidshipHelpCenter,
      ],
      icon: 'zidship_ar',
      iconCustomWidth: '20px',
      iconCustomHeight: '12px',
      permissionRequired: isPermissionRequired(UserPermissionSlugEnum.zidShip),
      labelTranslation: I18nMessages['sidebar.navigation_section.zidship'],
      hide: window.__INITIAL_STATE__.isAppWebViewUnderReview,
    },
    {
      name: RoutingRouteEnum.zidpay,
      route: isZidPay() ? RoutingRouteEnum.zidpay : RoutingRouteEnum.zidpayMarketing,
      icon: 'zidpay_ar',
      iconCustomWidth: '20px',
      iconCustomHeight: '12px',
      permissionRequired: isPermissionRequired(UserPermissionSlugEnum.zidpay),
      labelTranslation: I18nMessages['sidebar.navigation_section.zidpay'],
      hide: window.__INITIAL_STATE__.isAppWebViewUnderReview,
    },
    {
      name: 'appMarket',
      icon: 'zid_market_ar',
      iconCustomWidth: '26px',
      iconCustomHeight: '12px',
      href: '/market',
      labelTranslation: I18nMessages['sidebar.navigation_section.app_market.title'],
      hide: window.__INITIAL_STATE__.isAppWebViewUnderReview || hasMarketplaceConnect(),
    },
    {
      name: 'appMarket',
      icon: 'zid_market_ar',
      iconCustomWidth: '26px',
      iconCustomHeight: '12px',
      subRoutes: [
        RoutingRouteEnum.appMarket,
        RoutingRouteEnum.appMarketDetails,
        RoutingRouteEnum.appMarketEmbedded,
        RoutingRouteEnum.appMarketBrowseApps,
        RoutingRouteEnum.appMarketMyApps,
        RoutingRouteEnum.marketplace,
      ],
      labelTranslation: I18nMessages['sidebar.navigation_section.app_market.title'],
      hide: window.__INITIAL_STATE__.isAppWebViewUnderReview || !hasMarketplaceConnect(),
      items: [
        {
          name: 'appMarket',
          route: RoutingRouteEnum.appMarket,
          labelTranslation: I18nMessages['sidebar.navigation_section.app_market.title'],
        },
        {
          name: 'marketplace',
          route: RoutingRouteEnum.marketplace,
          labelTranslation: I18nMessages['sidebar.navigation_section.zid_marketplace'],
          tag: BadgeTypeMenuBased.new,
          permissionRequired: isPermissionRequired(UserPermissionSlugEnum.marketplace),
          hide: !hasMarketplaceConnect(),
        },
      ],
    },
    {
      name: 'ZidSolutions',
      href: 'https://solutions.zid.sa',
      icon: 'zid_partners_ar',
      iconCustomWidth: '26px',
      iconCustomHeight: '10px',
      labelTranslation: I18nMessages['sidebar.navigation_section.zid_solutions'],
      isExternal: true,
    },
    {
      name: isCharitableDonationStore() ? RoutingRouteEnum.themeStore : RoutingRouteEnum.themeMarketHome,
      href: isCharitableDonationStore() ? '/store-design#themes' : '/theme-market',
      icon: 'paint_roller_outline',
      labelTranslation: I18nMessages['sidebar.navigation_section.interface_options.theme_store'],
      subRoutes: [RoutingRouteEnum.themeMarketDetails, RoutingRouteEnum.themeMarketBrowse],
    },
    {
      name: 'contact-us',
      labelTranslation: I18nMessages['sidebar.navigation_section.contact-us.title'],
      customIcon: contactIcon,
      href: '#',
      hide: true,
    },
    {
      name: 'appManager',
      labelTranslation: I18nMessages['sidebar.navigation_section.mobile_app.title'],
      icon: 'mobile_outline',
      hide: hideApp(),
      subRoutes: [
        RoutingRouteEnum.mobileAppManagerPushNotifications,
        RoutingRouteEnum.mobileAppManagerDiscounts,
        RoutingRouteEnum.mobileAppManagerBanner,
      ],
      permissionRequired: isPermissionRequired(UserPermissionSlugEnum.mobileAppManager),
      route: RoutingRouteEnum.mobileAppManager,
    },
    {
      name: RoutingRouteEnum.mobileAppLandingPage,
      route: RoutingRouteEnum.mobileAppLandingPage,
      icon: 'mobile_outline',
      hide: !hideApp(),
      labelTranslation: I18nMessages['sidebar.navigation_section.mobile_app.title'],
    },
  ];
}
