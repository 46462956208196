import Vue, { VNode, CreateElement } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ZidAlert, ZidButton, ZidCol, ZidIcon, ZidRow, ZidTooltip } from '@zidsa/ui';
import { AnchorButtonSizeEnum } from '../../../common/components/AnchorButton/size.enum';
import { AnchorButtonTypeEnum } from '../../../common/components/AnchorButton/type.enum';
import { ButtonTypeEnum } from '../../../common/components/Button';
import { FontAwesomeIconTypeEnum } from '../../../common/components/FontAwesomeIcon';
import { HelpCenterComponent } from '../../../common/components/HelpCenter/HelpCenter';
import { I18nMessages } from '../../../i18n/messages';
import StatusBarUserProfileComponent from './UserProfile/UserProfile';
import UserStoreModule from '../../../user/store/module';
import { StatusBarLanguageSwitcherComponent } from './LanguageSwitcher/LanguageSwitcher';
import { StatusBarWalletComponent } from './ZidWallet/StatusBarWallet';
import { PurchaseStoreModule } from '../../../subscription/store/subscription-checkout/purchase/purchase-module';
import { WalletStatus } from '../../../zidwallet/components/WalletStatus/WalletStatus';
import { ZidWalletModalsStoreModule } from '../../../zidwallet/store/modal-booleans.module';
import { ZidWalletComponentsAddFundsLoaderModalComponent } from '../../../zidwallet/components/AddFundsModal/LoaderModal/LoaderModal';
import PinnedApps from './PinnedApps/PinnedApps';
import { PinnedAppAppInterface } from '../../types/pinned-app.interface';
import { AccountSettingsStoreModule } from '../../../settings/store/account-settings/module';
import { UserPermissionSlugEnum } from '../../../api/model/user/permission-slug.enum';
import { userStoreHasPermission } from '../../../user/store/has-permission';
import isSuspendedSubscription from '../../../routing/guards/is-suspended-subscription';
import ZidArLogo from '../../../common/assets/icons/ZidArLogo.vue';
import ZidEnLogo from '../../../common/assets/icons/ZidEnLogo.vue';
import Headphones from '../../../common/assets/icons/Headphones.vue';
import StatusBarGlobalSearchComponent from './GlobalSearch/GlobalSearch.vue';

import styles from './StatusBar.scss';
import { AppStoreModule } from '../../store/module';
import ApiModelUserInterface from '../../../api/model/user.interface';
import { RoutingRouteEnum } from '../../../routing/route.enum';

@Component
export default class StatusBarComponent extends Vue {
  @Prop()
  private readonly isCollapsed!: boolean;

  private isHelpCenterModalOpen = false;

  private showResponseModal = false;

  private showLoaderModal = false;

  private isScrolled = false;

  private isSearchInputOpen = false;

  private get purchaseStatus(): string | undefined {
    return PurchaseStoreModule.data?.purchase_status.code;
  }
  async created(): Promise<void> {
    AccountSettingsStoreModule.getFeatureFlagInfo();

    const URL = window.location.href;
    if (URL.includes('purchase_id=')) {
      const purchaseId = URL.split('purchase_id=')[1].split('&')[0]; // grabs the part on the right of the purchase_id=
      await PurchaseStoreModule.viewPurchaseStatusWallet(purchaseId);
      this.showLoaderModal = true;
      if (URL.includes('tap_id=')) {
        const tapId = URL.split('tap_id=')[1].split('&')[0];
        await PurchaseStoreModule.confirmWalletTopup({ purchaseId, tapId }).then(() => {
          this.showLoaderModal = false;
          this.showResponseModal = true;
        });
      }
    }
  }

  mounted(): void {
    this.$root.$on('toggleSidebar', () => {
      this.$emit('toggleSidebar');
    });
    window.addEventListener('scroll', this.handleScroll);
  }

  beforeDestroy(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private get showFundsModal(): boolean {
    return ZidWalletModalsStoreModule.showFundsModal;
  }

  render(h: CreateElement): VNode {
    return (
      <div>
        <div class={[this.mainClasses, { [styles.topmost]: isSuspendedSubscription() }]} id='merchantProfile'>
          <div class={styles.statusbar_container}>
            <ZidRow class={styles.statusbar_container__customrow}>
              <ZidCol class={styles.statusbar_container__customrow__customcol} cols='12' lg='9'>
                <div class={styles.statusbar_togglenavigationbarwrapper}>
                  <button
                    type='button'
                    class={styles.statusbar_togglenavigationbarwrapper__button}
                    onClick={this.onClickToggleNavigation}
                    disabled={isSuspendedSubscription()}
                  >
                    <span class={styles.statusbar_togglenavigationbarwrapper__button_icon} />
                  </button>
                </div>
                <div class={styles.statusbar__wrapper}>
                  {this.renderStatusBarStartActions(h)}
                  <StatusBarGlobalSearchComponent
                    onToggleSearchInput={this.onToggleSearchInput}
                    disabled={isSuspendedSubscription()}
                    class={[{ [styles.blurred]: isSuspendedSubscription() }]}
                  />
                  <transition name='fade'>{this.renderStatusBarEndActions(h)}</transition>
                </div>
              </ZidCol>
            </ZidRow>
          </div>
        </div>
        <HelpCenterComponent
          isOpen={this.isHelpCenterModalOpen}
          onClose={() => {
            this.isHelpCenterModalOpen = false;
          }}
        />
        <WalletStatus
          showResponseModal={this.showResponseModal}
          onToggleResponseModal={this.onToggleResponseModal}
          onToggleFundsModal={this.onToggleFundsModal}
          purchaseStatus={this.purchaseStatus}
        />
        <ZidWalletComponentsAddFundsLoaderModalComponent
          showLoaderModal={this.showLoaderModal}
          onToggleLoaderModal={this.onToggleLoaderModal}
        />
      </div>
    );
  }

  private renderStatusBarStartActions(h: CreateElement): VNode | void {
    return (
      <div class={[styles.statusbar__wrapper_actions, { [styles.searchOpened]: this.isSearchInputOpen }]}>
        {AppStoreModule.locale === 'ar' ? (
          <ZidArLogo class={styles.statusbar__wrapper_actions__logo} width='38' height='23' />
        ) : (
          <ZidEnLogo class={styles.statusbar__wrapper_actions__logo} height='23' width='57' />
        )}
        {this.hasWalletSectionPermissions && <StatusBarWalletComponent onToggleFundsModal={this.onToggleFundsModal} />}
        <div class={[this.classes, { [styles.blurred]: isSuspendedSubscription() }]}>
          <ZidTooltip>
            <ZidButton
              link
              type={AnchorButtonTypeEnum.primary}
              size={AnchorButtonSizeEnum.default}
              id='zid_notification_btn'
            >
              <ZidIcon icon='bell_outline' color='danger' size='xxs' type={FontAwesomeIconTypeEnum.solid} />
            </ZidButton>
            <template slot='tooltip'>{this.$t(I18nMessages['onboarding.updates_title'])}</template>
          </ZidTooltip>
        </div>
        <div class={[styles.statusbar__helpbtn, { [styles.blurred]: isSuspendedSubscription() }]}>
          <ZidTooltip>
            <ZidButton
              link
              type={ButtonTypeEnum.primary}
              nativeOnClick={this.showHelpCenterModal}
              disabled={isSuspendedSubscription()}
            >
              <Headphones />
            </ZidButton>
            <template slot='tooltip'>{this.$t(I18nMessages['statusbar.zam_help_center'])}</template>
          </ZidTooltip>
        </div>
        <ZidTooltip class={[{ [styles.blurred]: isSuspendedSubscription() }]}>
          <StatusBarLanguageSwitcherComponent isTopNavbar />
          <template slot='tooltip'>{this.$t(I18nMessages['statusbar.change_lang_tooltip'])}</template>
        </ZidTooltip>
      </div>
    );
  }

  private renderStatusBarEndActions(h: CreateElement): VNode | void {
    const isSubscriptionRecurring = this.user?.store.subscription.recurring;
    const isSubscriptionWarning = this.user?.store.subscription.status === 'wrn';
    const isSubscriptionSuspended = this.user?.store.subscription.is_suspended;

    return (
      <div class={[styles.statusbar__useractions, { [styles.searchOpened]: this.isSearchInputOpen }]}>
        {this.myPinnedApps && this.myPinnedApps?.length > 0 && <PinnedApps />}
        {!isSubscriptionRecurring && isSubscriptionWarning && !isSubscriptionSuspended && (
          <ZidTooltip>
            <ZidAlert
              nativeOnClick={this.goToSubscriptions}
              type={this.checkRemainingDaysColor}
              outlined={true}
              class={[styles.statusbar__warning__btn]}
              size='small'
              dismissible={false}
              defaultIcon={false}
            >
              {this.user?.store.subscription.remaining_cycle.days}
            </ZidAlert>
            <template slot='tooltip'>
              {this.$t(I18nMessages['subscriptions.trial_days_left'], {
                days: this.user?.store.subscription.remaining_cycle.days,
              })}
            </template>
          </ZidTooltip>
        )}

        <StatusBarUserProfileComponent class={[{ [styles.blurred]: isSuspendedSubscription() }]} />
      </div>
    );
  }

  private get user(): ApiModelUserInterface | null {
    return UserStoreModule?.data;
  }

  public get myPinnedApps(): PinnedAppAppInterface[] | undefined {
    return UserStoreModule?.data?.store.pinned_apps;
  }

  private get hasWalletSectionPermissions(): boolean {
    return userStoreHasPermission(UserPermissionSlugEnum.zidWallet);
  }

  public get checkRemainingDaysColor(): ButtonTypeEnum {
    const dangerRemainingDays = 3;
    const remainingDays = this.user?.store.subscription.remaining_cycle.days || 0;
    if (remainingDays <= dangerRemainingDays) {
      return ButtonTypeEnum.danger;
    }

    return ButtonTypeEnum.warning;
  }
  private onClickToggleNavigation(event: MouseEvent): void {
    event.stopPropagation();
    this.$emit('toggleSidebar');
  }

  private showHelpCenterModal(): void {
    this.isHelpCenterModalOpen = true;
  }

  private goToSubscriptions(): void {
    this.$router.push({ name: RoutingRouteEnum.subscriptionsIndex });
  }

  private onToggleFundsModal(): void {
    ZidWalletModalsStoreModule.toggleFundsModal();
  }

  private onToggleResponseModal(): void {
    this.showResponseModal = !this.showResponseModal;
  }

  private onToggleLoaderModal(): void {
    this.showLoaderModal = !this.showLoaderModal;
  }

  private get classes(): Record<string, boolean> {
    return {
      [styles['statusbar__btn']]: true,
      [styles['statusbar__updatesbtn']]: true && !isSuspendedSubscription(),
      [styles['statusbar__updatesbtn--disabled']]: isSuspendedSubscription(),
    };
  }

  private get mainClasses(): Record<string, boolean> {
    return {
      [styles['statusbar']]: true,
      [styles['opened']]: !this.isCollapsed,
      [styles['scrolled']]: this.isScrolled && !this.showFundsModal,
    };
  }

  private handleScroll(): void {
    this.isScrolled = window.scrollY > 0;
  }

  private onToggleSearchInput(value: boolean): void {
    this.isSearchInputOpen = value;
  }
}
