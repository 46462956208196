export const enum RoutingRouteEnum {
  home = 'home',
  analytics = 'analytics',
  login = 'login',
  loginBtns = 'loginBtns',
  loginMobile = 'loginMobile',
  loginWithPassword = 'loginWithPassword',
  resetPass = 'resetPass',
  register = 'register',
  registerWizard = 'registerWizard',
  presetup = 'presetup',
  thankYou = 'thankYou',
  otp = 'otp',
  changeMobile = 'changeMobile',
  newPassword = 'newPassword',
  orders = 'orders',
  orderNotifications = 'orderNotifications',
  orderNotificationsPricing = 'orderNotificationsPricing',
  orderDetails = 'orderDetails',
  reverseOrder = 'reverseOrder',
  ordersInvoices = 'ordersInvoices',
  products = 'products',
  productsForm = 'productsForm',
  product = 'product',
  createProduct = 'createProduct',
  editProduct = 'editProduct',
  optionsLibrary = 'optionsLibrary',
  productOption = 'productOption',
  productVariant = 'productVariant',
  donations = 'donations',
  createDonation = 'createDonation',
  editDonation = 'editDonation',
  editDonationOld = 'editDonationOld',
  donation = 'donation',
  categories = 'categories',
  category = 'category',
  coupons = 'coupons',
  fosteringNgo = 'fosteringNgo',
  getCoupon = 'getCoupon',
  couponsReports = 'couponsReports',
  addCoupon = 'addCoupon',
  editCoupon = 'editCoupon',
  viewCoupon = 'viewCoupon',
  orderCreation = 'orderCreation',
  orderDraft = 'orderDraft',
  orderDrafts = 'orderDrafts',
  notFound = 'notFound',
  zidShip = 'zidShip',
  zidshipServiceLevelDetails = 'zidshipServiceLevelDetails',
  zidshipAddPricingZone = 'zidshipAddPricingZone',
  zidshipEditPricingZone = 'zidshipEditPricingZone',
  zidshipHelpCenter = 'zidshipHelpCenter',
  zidshipShipments = 'zidshipShipments',
  zidshipWallet = 'zidShipWallet',
  zidshipReports = 'zidShipMerchantReports',
  zidshipWaybillsPackages = 'zidshipWaybillsPackages',
  zidshipAccountSettings = 'zidshipAccountSettings',
  bundleOffers = 'bundleOffers',
  bundleOfferCreation = 'bundleOfferCreation',
  bundleOfferEdit = 'bundleOfferEdit',
  affiliateList = 'affiliateList',
  qitafProgram = 'qitafProgram',
  loyaltyProgram = 'loyaltyProgram',
  landingPages = 'landingPages',
  landingPagesCreation = 'landingPagesCreation',
  landingPagesEdit = 'landingPagesEdit',
  affiliateCreation = 'affiliateCreation',
  affiliateDetails = 'affiliateDetails',
  abandonedCarts = 'abandonedCarts',
  abandonedCartsDetails = 'abandonedCartsDetails',
  zidpay = 'zidpay',
  zidpayDeposits = 'zidpayDeposits',
  zidpayDeposit = 'zidpayDeposit',
  zidpayTransaction = 'zidpayTransaction',
  zidpayTransactions = 'zidpayTransactions',
  zidpayPaymentLink = 'zidpayPaymentLink',
  zidpayPaymentLinks = 'zidpayPaymentLinks',
  zidpayMarketing = 'zidpayMarketing',
  zidpayActivation = 'zidpayActivation',
  zidpayActivationConfirmation = 'zidpayActivationConfirmation',
  zidpayAfterActivation = 'zidpayAfterActivation',
  zidpaySettings = 'zidpaySettings',
  finance = 'finance',
  financeMarketing = 'financeMarketing',
  financeActivation = 'financeActivation',
  financeLanding = 'financeLanding',
  mazeed = 'mazeed',
  mazeedSettings = 'mazeedSettings',
  mazeedSettlementInvoice = 'mazeedSettlementInvoice',
  mazeedSettlements = 'mazeedSettlements',
  themeStore = 'themeStore',
  themeAdded = 'themeAdded',
  customers = 'customers',
  customersGroups = 'customersGroups',
  customersGroup = 'customersGroup',
  customer = 'customer',
  customerAddresses = 'customerAddresses',
  shippingOptions = 'shippingOptions',
  shippingRequirments = 'shippingRequirments',
  shippingActivation = 'shippingActivation',
  shippingDetails = 'shippingDetails',
  shippingDetailsUpdate = 'shippingDetailsUpdate',
  settings = 'settings',
  settingsStore = 'settingsStore',
  settingsInventoryAddresses = 'settingsInventoryAddresses',
  settingsInventoryCreation = 'settingsInventoryCreation',
  settingsInventoryAddress = 'settingsInventoryAddress',
  settingsOrdersProductsSettings = 'settingsOrdersProductsSettings',
  settingsAccount = 'settingsAccount',
  settingsInventoryAddressesV2 = 'settingsInventoryAddressesV2',
  settingsInventoryAddressesV2Priority = 'settingsInventoryAddressesV2Priority',
  settingsInventoryAddressesV2Stocks = 'settingsInventoryAddressesV2Stocks',
  settingsInventoryCreationV2 = 'settingsInventoryCreationV2',
  settingsInventoryAddressV2 = 'settingsInventoryAddressV2',
  settingsInventoryAddressV2Stocktaking = 'settingsInventoryAddressV2Stocktaking',
  settingsInventoryAddressV2CreateStocktaking = 'settingsInventoryAddressV2CreateStocktaking',
  settingsInventoryAddressV2CreateStocktakingCount = 'settingsInventoryAddressV2CreateStocktakingCount',
  settingsInventoryAddressV2StocktakingOverview = 'settingsInventoryAddressV2StocktakingOverview',
  settingsInventoryTransferRequests = 'settingsInventoryTransferRequests',
  settingsInventoryTransferRequestDetails = 'settingsInventoryTransferRequestDetails',
  settingsInventoryTransferRequestCreation = 'settingsInventoryTransferRequestCreation',
  settingsInventoryTransferRequestModification = 'settingsInventoryTransferRequestModification',
  settingsInventoryTransferRequestReceive = 'settingsInventoryTransferRequestReceive',
  settingsCurrentDomain = 'settingsCurrentDomain',
  settingsCurrentDomainRecordVerification = 'settingsCurrentDomainRecordVerification',
  settingsLinkExistingDomain = 'settingsLinkExistingDomain',
  checkoutOptions = 'checkoutOptions',
  cutomizeCustomerAddress = 'cutomizeCustomerAddress',
  giftOptions = 'giftOptions',
  paymentOptions = 'paymentOptions',
  otherPaymentOptions = 'otherPaymentOptions',
  thirdPartyProviderDetails = 'thirdPartyProviderDetails',
  domains = 'domains',
  appMarket = 'appMarket',
  appMarketDetails = 'appMarketDetails',
  appMarketEmbedded = 'appMarketEmbedded',
  appMarketMyApps = 'appMarketMyApps',
  appMarketAppsCollection = 'appMarketAppsCollection',
  appMarketBrowseApps = 'appMarketBrowseApps',
  marketplace = 'marketplace',
  storeDesign = 'storeDesign',
  subscriptionsCheckout = 'subscriptionsCheckout',
  subscriptionPackages = 'subscriptionPackages',
  subscriptionsCheckoutThank = 'subscriptionsCheckoutThank',
  subscriptionsIndex = 'subscriptionsIndex',
  subscriptionPurchases = 'subscriptionPurchases',
  subscriptionAddons = 'subscriptionAddons',
  subscriptionSavedCards = 'subscriptionSavedCards',
  subscriptionSaveCard = 'subscriptionSaveCard',
  subscriptionsSuspended = 'subscriptionsSuspended',
  filtrationAttribute = 'filtrationAttribute',
  zidpos = 'zidpos',
  zidposOnboarding = 'zidposOnboarding',
  zidposReports = 'zidposReports',
  zidposPaymentMethods = 'zidposPaymentMethods',
  zidposCashiers = 'zidposCashiers',
  zidposNewCashier = 'zidposNewCashier',
  zidposSubscribe = 'zidposSubscribe',
  zidposPreSubscription = 'zidposPreSubscription',
  themeMarketDetails = 'themeMarketDetails',
  themeMarketHome = 'themeMarketHome',
  themeMarketBrowse = 'themeMarketBrowse',
  mobileAppLandingPage = 'mobileAppLandingPage',
  mobileAppManager = 'mobileAppManager',
  mobileAppManagerPushNotifications = 'mobileAppManagerPushNotifications',
  mobileAppManagerDiscounts = 'mobileAppManagerDiscounts',
  mobileAppManagerBanner = 'mobileAppManagerBanner',
  zidWallet = 'zidWallet',
  ticketing = 'ticketing',
  createTicket = 'createTicket',
  ticketDetails = 'ticketDetails',
  MerchantGrowthRegistration = 'MerchantGrowthRegistration',
  MerchantGrowthMainPage = 'MerchantGrowthMainPage',
  MerchantGrowth = 'MerchantGrowth',
  smsCampaignsPricing = 'smsCampaignsPricing',
  smsCampaigns = 'smsCampaigns',
  createSmsCampaign = 'createSmsCampaign',
  editSmsCampaign = 'editSmsCampaign',
  viewSmsCampaign = 'viewSmsCampaign',
  completeStoreInfo = 'completeStoreInfo',
  bulkEdit = 'bulkEdit',
  promotionalMessages = 'promotionalMessages',
  abandonedCartsMessages = 'abandonedCartsMessages',
  abandonedCartsCreateMessage = 'abandonedCartsCreateMessage',
  langCurrency = 'langCurrency',
  StoreLink = 'StoreLink',
  StoreSaudiBusinessVerify = 'StoreSaudiBusinessVerify',
  StoreSaudiContactInfoForm = 'StoreSaudiContactInfoForm',
  StoreSaudiVatInfoForm = 'StoreSaudiVatInfoForm',
  analyticsV2 = 'analyticsV2',
  liveAnalytics = 'liveAnalytics',
  analyticsReports = 'analyticsReports',
  faiApp = 'faiApp',
  groupedProduct = 'groupedProduct',
  editGroupedProduct = 'editGroupedProduct',
  questionsRatings = 'questionsRatings',
  createConstraint = 'createConstraint',
  editConstraint = 'editConstraint',
  constraintsPaymentAndShipping = 'constraintsPaymentAndShipping',
  ratingsNotifications = 'ratingsNotifications',
  ratingsQuestionsMessages = 'ratingsQuestionsMessages',
  questionsNotifications = 'questionsNotifications',
  similarProducts = 'similarProducts',
  productsStockHistory = 'productsStockHistory',
  notifyRestockedProduct = 'notifyRestockedProduct',
  notifyRestockedProductCreateMessage = 'notifyRestockedProductCreateMessage',
  productsRestore = 'productsRestore',
  metafields = 'metafields',
  addMetafield = 'addMetafield',
  editMetafield = 'editMetafield',
  addExtraPage = 'addExtraPage',
  editExtraPage = 'editExtraPage',
  invoiceNotReady = 'invoiceNotReady',
  subscriptionsManagement = 'subscriptionsManagement',
  userManagement = 'userManagement',
  permissionGroups = 'permissionGroups',
  createPermissionsGroup = 'createPermissionsGroup',
  editPermissionsGroup = 'editPermissionsGroup',
  createUser = 'createUser',
  editUser = 'editUser',
}
