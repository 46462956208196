import ApiClient from '../../../api/client';
import ApiHttpMethodEnum from '../../../api/http-method.enum';
import { settingsApiUrls } from '../urls';
import SettingsTypesAccountSettingsInterface from '../../types/account-settings.interface';
import {
  SettingsApiAccountSettingsResponseInterface,
  SettingsApiAccountSettingsSessionLogoutResponseInterface,
  SettingsApiFeatureFlagsResponseInterface,
} from './response.interface';
import SettingsTypesAccountPasswordInterface from '../../types/account-password.interface';
import { ApiBaseDataResponseObjectType } from '../../../api/types';
import SettingsTypesModeratorInterface from '../../types/moderator.interface';
import { SettingsTypesAccountSettingsType } from '../../types/account-settings.type';
import { SettingAccountSessionLogoutEnums } from '../../enums/session-logout-strictness.enum';

export class SettingsApiAccountService {
  constructor(private readonly apiClient: ApiClient) {}

  public async updateAccountSettings(
    data: SettingsTypesAccountSettingsInterface,
  ): Promise<SettingsApiAccountSettingsResponseInterface> {
    const response = await this.apiClient.request<SettingsApiAccountSettingsResponseInterface>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.updateAccountSettings,
      {
        data,
      },
    );

    return response.data;
  }

  public async updateAccountPassword(
    data: SettingsTypesAccountPasswordInterface,
  ): Promise<ApiBaseDataResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.updateAccountPassword,
      { data },
    );

    return response.data;
  }

  public async getFeatureFlags(): Promise<SettingsApiFeatureFlagsResponseInterface> {
    const response = await this.apiClient.request<SettingsApiFeatureFlagsResponseInterface>(
      ApiHttpMethodEnum.get,
      settingsApiUrls.getFeatureFlagsInfo,
    );

    return response.data;
  }

  public async getModerator(
    moderatorID: string,
  ): Promise<ApiBaseDataResponseObjectType<SettingsTypesModeratorInterface>> {
    const url = settingsApiUrls.moderatorSettings.replace('{id}', moderatorID);

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<SettingsTypesModeratorInterface>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async updateModerator(
    moderatorID: string,
    data: SettingsTypesModeratorInterface,
  ): Promise<ApiBaseDataResponseObjectType<SettingsTypesModeratorInterface>> {
    const url = settingsApiUrls.moderatorSettings.replace('{id}', moderatorID);

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<SettingsTypesModeratorInterface>>(
      ApiHttpMethodEnum.put,
      url,
      { data },
    );

    return response.data;
  }

  public async updateLogoutControl(data: {
    strictness: SettingAccountSessionLogoutEnums;
  }): Promise<SettingsApiAccountSettingsSessionLogoutResponseInterface> {
    const response = await this.apiClient.request<SettingsApiAccountSettingsSessionLogoutResponseInterface>(
      ApiHttpMethodEnum.put,
      settingsApiUrls.updateSessionLogoutStrictness,
      { data },
    );

    return response.data;
  }

  public async signOutFromAllDevices(): Promise<SettingsApiFeatureFlagsResponseInterface> {
    const response = await this.apiClient.request<SettingsApiFeatureFlagsResponseInterface>(
      ApiHttpMethodEnum.post,
      settingsApiUrls.signoutFromAllDevices,
    );

    return response.data;
  }

  public async getAccountSettings(): Promise<ApiBaseDataResponseObjectType<SettingsTypesAccountSettingsType>> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<SettingsTypesAccountSettingsType>>(
      ApiHttpMethodEnum.get,
      settingsApiUrls.accountSettings,
    );

    return response.data;
  }
}
