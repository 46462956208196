import { OrdersApiDraftProductsRequestInterface } from './orders/drafts/products/request.interface';
/* eslint-disable @typescript-eslint/camelcase */
import { OrdersApiDraftsFiltersInterface } from './drafts/filters.interface';
import { OrdersApiDraftsResponseInterface } from './drafts/reponse.interface';
import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { ordersApiUrls } from './urls';
import {
  ApiBaseDataResponseObjectType,
  ApiBaseMessageResponseObjectType,
  ApiBaseResponseType,
  ApiResponseType,
} from '../../api/types';
import { OrdersApiResponseInterface } from './response.interface';
import { OrdersApiFiltersInterface } from './filters.interface';
import {
  ApiModelOrderInterface,
  ApiModelOrderPrintInterface,
  ApiModelOrderSummaryInterface,
  ApiModelOrderTagInterface,
} from '../../api/model/order.interface';
import { ordersApiOrdersFiltersToSearchParams } from './orders/filters-to-search-params';
import { ApiModelOrderStatusEnum } from '../../api/model/order/status.enum';
import { OrdersApiExportAllFiltersInterface } from './export-all/filters.interface';
import { ordersApiExportAllFiltersToSearchParams } from './export-all/filters-to-search-params';
import { urlQueryFormat } from '../../common/helpers/url/query/format';
import { ordersApiSalesReportFiltersToSearchParams } from './sales-report/filters-to-search-params';
import { ordersApiDonationsReportFiltersToSearchParams } from './donations-report/filters-to-search-params';
import { OrdersApiSalesReportFiltersInterface } from './sales-report/filters.interface';
import { OrdersApiDonationsReportFiltersInterface } from './donations-report/filters.interface';
import { OrdersApiBulkActionOptionsInterface } from './bulk-action/options.interface';
import { ordersApiBulkActionOptionsToSearchParams } from './bulk-action/options-to-search-params';
import { OrdersApiBulkActionResponseInterface } from './bulk-action/response.interface';
import { OrdersApiBulkActionStatusResponseInterface } from './bulk-action-status/response.interface';
import { urlFormatParams } from '../../common/helpers/url/format-params';
import { OrdersApiDraftConfirmationResponseInterface } from './orders/drafts/confirmation/response.interface';
import { ApiModelOrderDraftShippingMethodsInterface } from '../../api/model/order/draft/shipping-methods.interface';
import { ApiModelOrderDraftCustomerAddressInterface } from '../../api/model/order/draft/customer-address.interface';
import { ApiModelOrderDraftSessionIdInterface } from '../../api/model/order/draft/session-id.interface';
import { ApiModelOrderDraftSessionInterface } from '../../api/model/order/draft/session.interface';
import { ApiModelCustomerPrimaryInterface } from '../../api/model/customer-primary.interface';
import { ApiModelOrderPaymentMethodInterface } from '../../api/model/order/payment-method.interface';
import { ordersApiDraftsFiltersToSearchParams } from './drafts/filters-to-search-params';
import { ApiModelOrderProductInterface } from '../../api/model/order/product.interface';
import { ApiModelOrderDraftConsigneeAddressInterface } from '../../api/model/order/draft/consignee-address.interface';
import { OrderCancelComment } from './drafts/orderCancelComment.interface';
import Axios from 'axios';
import { OrdersApiUploadOptionFileResponseInterface } from './upload-image/response.interface';
import { OrdersApiDraftProductsUpdateRequestInterface } from './orders/drafts/products/update-request.interface';
import { OrdersApiDraftProductsDeleteRequestInterface } from './orders/drafts/products/delete-request.interface';
import { OrdersApiGetInvoicesResponseInterface } from './invoice/get-invoices-response.interface';
import Catch from '../../common/decorators/catch-error';
import { PaymentMethodFormData } from '../components/OrderCreation/PaymentMethodStep/PaymentMethodFormData.interface';
import { DeliveryOptions } from '../components/OrderCreation/DeliveryOptionStep/DeliveryOptions.enum';
import { zidShipApiUrls } from '../../zidship/api/urls';
import { OrderFirstMilePickupResponseInterface } from './orders/first-mile-pickup-response.interface';
import { ApiModelReverseOrderRequestInterface } from '../../api/model/reverse-order-request.interface';
import { OrdersApiReversedReasonsInterface } from './orders/reversed-reasons.interface';
import { SettingsApiInventoryAddressesResponseInterface } from '../../settings/api/inventory-addresses/response.interface';
import { OrderFirstMileHasPickupFeatureInterface } from './orders/has-first-mile-pickup-feature-response.interface';
import { serviceLevelsCodeEnum } from '../../zidship/types/model/service-level.interface';
import { OrderPIIResponseInterface } from './order-pii/response.interface';
import { OrdersApiDraftConfirmationRequestInterface } from './orders/drafts/confirmation/request.interface';
import { OrdersApiGetOrdersHighlightsResponseInterface } from './orders/orders-highlights.interface';

export class OrdersApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getOrders(
    filters: OrdersApiFiltersInterface,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<OrdersApiResponseInterface>>> {
    const params = urlQueryFormat({
      ...ordersApiOrdersFiltersToSearchParams(filters),
    });

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<OrdersApiResponseInterface>>(
      ApiHttpMethodEnum.get,
      ordersApiUrls.orders,
      {
        params,
        cacheable: true,
      },
    );

    return response;
  }

  public async getOrderDetails(
    orderId: number,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ApiModelOrderInterface>>> {
    const url = ordersApiUrls.orderDetails.replace('{orderId}', orderId.toString());

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelOrderInterface>>(
      ApiHttpMethodEnum.get,
      url,
      {
        cacheable: true,
      },
    );

    return response;
  }

  public async getReturnOrderDetails(orderId: number): Promise<ApiBaseResponseType<ApiModelOrderInterface>> {
    const url = ordersApiUrls.returnOrder.replace('{orderId}', orderId.toString());

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderInterface>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async returnOrderRequest(
    data: ApiModelReverseOrderRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelReverseOrderRequestInterface>> {
    const url = ordersApiUrls.returnOrderRequest;

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelReverseOrderRequestInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: { ...data, reason: data.reason.length > 0 ? data.reason : undefined },
      },
    );

    return response.data;
  }

  public async getReverseOrderReasons(): Promise<ApiBaseResponseType<OrdersApiReversedReasonsInterface[]>> {
    const url = ordersApiUrls.reverseOrderReasons;

    const response = await this.apiClient.request<ApiBaseResponseType<OrdersApiReversedReasonsInterface[]>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async addNewReasonsForOrderReverse(data: {
    name: string;
    store_id: number;
  }): Promise<ApiBaseResponseType<OrdersApiReversedReasonsInterface>> {
    const url = ordersApiUrls.reverseOrderReasons;
    const response = await this.apiClient.request<ApiBaseResponseType<OrdersApiReversedReasonsInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: {
          name: data.name,
          store_id: data.store_id,
        },
      },
    );
    return response.data;
  }

  public async requestReverseWaybill(data: {
    orderId: number;
    isStandaloneZidshipWaybill: boolean;
  }): Promise<ApiBaseDataResponseObjectType<ApiModelReverseOrderRequestInterface>> {
    const url = ordersApiUrls.requestReverseWaybill;

    const response = await this.apiClient.request(ApiHttpMethodEnum.post, url, {
      data: { order_id: data.orderId, is_standalone_zidship_waybill: data.isStandaloneZidshipWaybill },
    });

    return response.data;
  }

  public async changeOrderStatus(
    orderId: number,
    status: ApiModelOrderStatusEnum,
    inventory_address_id?: string,
    packagesCount?: string,
    comment?: OrderCancelComment,
    ignoreCapacity?: string,
  ) {
    const url = ordersApiUrls.orderChangeStatus.replace('{orderId}', orderId.toString());

    const data: {
      code: string;
      inventory_address_id?: string;
      params?: {
        packages_count: string;
        ignore_capacity: string;
      };
      comment?: OrderCancelComment;
    } = {
      code: status.toString(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      inventory_address_id,
      params: {
        packages_count: packagesCount || '1',
        ignore_capacity: ignoreCapacity || '0',
      },
      comment,
    };

    const response = await this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url, {
      data,
    });

    return response.data;
  }

  public async orderEditStartAction(orderId: number): Promise<any> {
    const url = ordersApiUrls.editableOrder.replace('{orderId}', orderId.toString());

    const response = await this.apiClient.request<any>(ApiHttpMethodEnum.get, url);
    return response.data;
  }

  public async getOrderPickupOptions(orderId: number | null): Promise<SettingsApiInventoryAddressesResponseInterface> {
    const url = ordersApiUrls.orderPickupOptions.replace('{orderId}', orderId ? orderId.toString() : 'undefined');

    const response = await this.apiClient.request<SettingsApiInventoryAddressesResponseInterface>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async getDraftSessionId(): Promise<ApiBaseResponseType<ApiModelOrderDraftSessionIdInterface>> {
    const url = ordersApiUrls.orderCreateDraft;

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderDraftSessionIdInterface>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async getDraftSession(sessionId: string): Promise<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>> {
    const url = ordersApiUrls.orderDraftSession.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async setDraftCurrency(sessionId: string, currency: string): Promise<ApiBaseResponseType> {
    const url = ordersApiUrls.orderDraftCurrency.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url, {
      data: {
        currency,
      },
    });
    return response.data;
  }

  public async setDraftCustomer(
    sessionId: string,
    // eslint-disable-next-line @typescript-eslint/camelcase
    customer_id: string,
  ): Promise<ApiBaseResponseType<ApiModelCustomerPrimaryInterface>> {
    const url = ordersApiUrls.orderDraftCustomer.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelCustomerPrimaryInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: {
          customer_id,
        },
      },
    );
    return response.data;
  }

  public async getDraftCustomerAddresses(
    sessionId: string,
  ): Promise<ApiBaseResponseType<Array<ApiModelOrderDraftCustomerAddressInterface>>> {
    const url = ordersApiUrls.orderDraftCustomerAddresses.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<
      ApiBaseResponseType<Array<ApiModelOrderDraftCustomerAddressInterface>>
    >(ApiHttpMethodEnum.get, url);

    return response.data;
  }

  public async getDraftDeliveryOptions(
    sessionId: string,
  ): Promise<ApiBaseResponseType<ApiModelOrderDraftShippingMethodsInterface>> {
    const url = ordersApiUrls.orderDraftDeliveryOptions.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderDraftShippingMethodsInterface>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async getDraftPaymentMethods(
    sessionId: string,
  ): Promise<ApiBaseResponseType<Array<ApiModelOrderPaymentMethodInterface>>> {
    const url = ordersApiUrls.orderDraftPaymentMethods.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<Array<ApiModelOrderPaymentMethodInterface>>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async setDraftCustomerAddress(
    sessionId: string,
    address_id: string,
    giftAddress: ApiModelOrderDraftConsigneeAddressInterface,
  ): Promise<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>> {
    const url = ordersApiUrls.orderDraftCustomerAddress.replace('{sessionId}', sessionId);

    const addressObj = Object(giftAddress);

    const consignee_name = addressObj ? addressObj.consignee_name : null;
    const consignee_city_id = addressObj ? addressObj.address_city_id : null;
    const consignee_country_id = addressObj ? addressObj.address_country_id : null;
    const consignee_district = addressObj ? addressObj.address_district : null;
    const consignee_street = addressObj ? addressObj.address_street : null;
    const consignee_email = addressObj ? addressObj.consignee_email : null;
    const consignee_short_address = addressObj ? addressObj.consignee_short_address : null;
    const is_consignee_notifiable = addressObj ? addressObj.is_consignee_notifiable : null;
    const consignee_telephone = addressObj ? addressObj.consignee_telephone : null;
    const has_different_consignee = consignee_name ? 1 : 0;
    const consignee_country_code = addressObj ? addressObj.consignee_country_code : null;
    const consignee_lat = 22.41;
    const consignee_lng = 22.41;

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: {
          address_id,
          consignee_name,
          consignee_city_id,
          consignee_country_id,
          consignee_country_code,
          consignee_district,
          consignee_street,
          consignee_short_address,
          consignee_email,
          is_consignee_notifiable,
          consignee_telephone,
          has_different_consignee,
          consignee_lat,
          consignee_lng,
        },
      },
    );
    return response.data;
  }

  public async setDraftDeliveryOption(
    sessionId: string,
    shipping_method_type: DeliveryOptions,
    shipping_method_id: number,
  ): Promise<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>> {
    const url = ordersApiUrls.orderDraftDeliveryOptions.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: {
          shipping_method_type,
          shipping_method_id,
        },
      },
    );
    return response.data;
  }

  public async setDraftPaymentMethod(
    sessionId: string,
    data: PaymentMethodFormData,
  ): Promise<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>> {
    const url = ordersApiUrls.orderDraftPaymentMethods.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: {
          ...data,
          store_payment_method_id: data.storePaymentMethodId,
          send_sms: data.isSms,
          send_email: data.isEmail,
        },
      },
    );
    return response.data;
  }

  public async verifyDraftSession(sessionId: string): Promise<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>> {
    const url = ordersApiUrls.orderDraftVerification.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>>(
      ApiHttpMethodEnum.post,
      url,
    );
    return response.data;
  }

  public async addCouponToDraft(
    sessionId: string,
    data: {
      coupon_code: string;
    },
  ): Promise<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>> {
    const url = ordersApiUrls.orderDraftCoupon.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>>(
      ApiHttpMethodEnum.post,
      url,
      { data },
    );

    return response.data;
  }

  public async removeCouponFromDraft(
    sessionId: string,
  ): Promise<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>> {
    const url = ordersApiUrls.orderDraftCoupon.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelOrderDraftSessionInterface>>(
      ApiHttpMethodEnum.delete,
      url,
    );
    return response.data;
  }

  public async confirmDraft(
    sessionId: string,
    data: OrdersApiDraftConfirmationRequestInterface,
  ): Promise<ApiBaseResponseType<OrdersApiDraftConfirmationResponseInterface>> {
    const url = ordersApiUrls.orderDraftConfirmation.replace('{sessionId}', sessionId);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      const value = (data as any)[key];
      if (key === 'bank_transfer_receipt' && !!value && value[0]) {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    });

    const response = await this.apiClient.request<ApiBaseResponseType<OrdersApiDraftConfirmationResponseInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  }

  public async exportOrders(filters: OrdersApiExportAllFiltersInterface): Promise<ApiBaseResponseType> {
    const params = urlQueryFormat(ordersApiExportAllFiltersToSearchParams(filters));

    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, ordersApiUrls.exportOrders, {
        data: params,
      })
      .then((response) => response.data);
  }

  public async getAirwaybills(
    orderIds: Array<number | string>,
    includeInvoice?: boolean,
  ): Promise<ApiBaseResponseType> {
    let path = ordersApiUrls.airwayBills + `?order_ids=${orderIds.join(',')}`;
    if (includeInvoice) path += '&include_invoice=1';

    return this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.get, path).then((response) => response.data);
  }

  public async confirmBankTransfer(orderId: number): Promise<any> {
    const url = ordersApiUrls.confirmBankTransfer.replace('{orderId}', orderId.toString());

    return this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url).then((response) => response.data);
  }

  public async downloadOrders(orderIds: Array<number>): Promise<Blob> {
    const params = urlQueryFormat({ orders: orderIds }, { arrayFormat: 'comma' });

    return this.apiClient
      .request<Blob>(ApiHttpMethodEnum.get, ordersApiUrls.downloadOrders, {
        params,
        responseType: 'blob',
      })
      .then((response) => response.data);
  }

  public async prepareOrders(
    ordersCount: number,
    filters: OrdersApiFiltersInterface,
  ): Promise<Blob | ApiBaseResponseType> {
    //might need work
    const params = urlQueryFormat(ordersApiExportAllFiltersToSearchParams(filters));
    const DOWNLOAD_LIMIT = 100;

    return this.apiClient
      .request<Blob | ApiBaseResponseType>(ApiHttpMethodEnum.post, ordersApiUrls.prepareOrders, {
        data: params,
        responseType: ordersCount >= DOWNLOAD_LIMIT ? 'json' : 'blob',
      })
      .then((response) => response.data);
  }

  public async salesReport(filters: OrdersApiSalesReportFiltersInterface): Promise<ApiBaseResponseType> {
    const params = urlQueryFormat(ordersApiSalesReportFiltersToSearchParams(filters));

    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, ordersApiUrls.salesReport, {
        data: params,
      })
      .then((response) => response.data);
  }

  public async donationsReport(filters: OrdersApiDonationsReportFiltersInterface): Promise<ApiBaseResponseType> {
    const params = urlQueryFormat(ordersApiDonationsReportFiltersToSearchParams(filters));

    return this.apiClient
      .request<ApiBaseResponseType>(ApiHttpMethodEnum.post, ordersApiUrls.donationsReport, {
        data: params,
      })
      .then((response) => response.data);
  }

  public async setDraftProducts(
    sessionId: string,
    data: OrdersApiDraftProductsRequestInterface,
  ): Promise<ApiBaseResponseType<Array<ApiModelOrderProductInterface>>> {
    const url = ordersApiUrls.orderDraftProducts.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseResponseType<Array<ApiModelOrderProductInterface>>>(
      ApiHttpMethodEnum.post,
      url,
      { data },
    );
    return response.data;
  }

  public async updateDraftProducts(
    sessionId: string,
    data: OrdersApiDraftProductsUpdateRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<Array<ApiModelOrderProductInterface>>> {
    const url = ordersApiUrls.orderDraftUpdateProducts.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<Array<ApiModelOrderProductInterface>>>(
      ApiHttpMethodEnum.post,
      url,
      { data },
    );
    return response.data;
  }

  public async deleteDraftProduct(
    sessionId: string,
    data: OrdersApiDraftProductsDeleteRequestInterface,
  ): Promise<ApiBaseDataResponseObjectType<Array<ApiModelOrderProductInterface>>> {
    const url = ordersApiUrls.orderDraftDeleteProduct.replace('{sessionId}', sessionId);

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<Array<ApiModelOrderProductInterface>>>(
      ApiHttpMethodEnum.post,
      url,
      { data },
    );
    return response.data;
  }

  public async uploadFileOption(
    sessionId: string,
    file: File,
    isImage: boolean,
  ): Promise<OrdersApiUploadOptionFileResponseInterface> {
    const url = ordersApiUrls[isImage ? 'uploadImageOption' : 'uploadFileOption'].replace('{sessionId}', sessionId);
    const imageData = new FormData();
    imageData.append('file', file);
    return Axios.post(url, imageData).then((response) => response.data);
  }
  public async bulkAction(options: OrdersApiBulkActionOptionsInterface): Promise<OrdersApiBulkActionResponseInterface> {
    const params = urlQueryFormat(ordersApiBulkActionOptionsToSearchParams(options));

    return this.apiClient
      .request<OrdersApiBulkActionResponseInterface>(ApiHttpMethodEnum.post, ordersApiUrls.bulkAction, {
        data: params,
      })
      .then((response) => response.data);
  }

  public async bulkActionStatus(actionId: string): Promise<OrdersApiBulkActionStatusResponseInterface> {
    const url = urlFormatParams(ordersApiUrls.bulkActionStatus, { actionId });

    return this.apiClient
      .request<OrdersApiBulkActionStatusResponseInterface>(ApiHttpMethodEnum.get, url)
      .then((response) => response.data);
  }

  public async getDrafts(
    filters: OrdersApiDraftsFiltersInterface,
  ): Promise<ApiBaseResponseType<OrdersApiDraftsResponseInterface>> {
    const url = ordersApiUrls.orderDrafts;

    const params = urlQueryFormat(ordersApiDraftsFiltersToSearchParams(filters));

    const response = await this.apiClient.request<ApiBaseResponseType<OrdersApiDraftsResponseInterface>>(
      ApiHttpMethodEnum.get,
      url,
      {
        params,
      },
    );

    return response.data;
  }

  public async deleteDraft(sessionId: string): Promise<ApiBaseResponseType> {
    const url = ordersApiUrls.orderDraftSession.replace('{sessionId}', sessionId);
    return this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.delete, url).then((response) => response.data);
  }

  @Catch()
  public async getInvoices(
    orderIds: string,
  ): Promise<ApiBaseDataResponseObjectType<OrdersApiGetInvoicesResponseInterface<ApiModelOrderPrintInterface>>> {
    const params = urlQueryFormat({ order_id: orderIds });

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<OrdersApiGetInvoicesResponseInterface<ApiModelOrderPrintInterface>>
    >(ApiHttpMethodEnum.get, ordersApiUrls.ordersInvoices, {
      params,
    });

    return response.data;
  }

  @Catch()
  public async getSummaries(
    orderIds: string,
  ): Promise<ApiBaseDataResponseObjectType<OrdersApiGetInvoicesResponseInterface<ApiModelOrderSummaryInterface>>> {
    const params = urlQueryFormat({ order_id: orderIds });

    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<OrdersApiGetInvoicesResponseInterface<ApiModelOrderSummaryInterface>>
    >(ApiHttpMethodEnum.get, ordersApiUrls.ordersSummaries, {
      params,
    });

    return response.data;
  }

  public async deleteTag(orderId: number, tagId: string): Promise<ApiBaseMessageResponseObjectType> {
    const url = ordersApiUrls.deleteOrderTag.replace('{orderId}', orderId.toString()).replace('{tagId}', tagId);
    return this.apiClient
      .request<ApiBaseMessageResponseObjectType>(ApiHttpMethodEnum.delete, url)
      .then((response) => response.data);
  }

  public async getTags(searchTerm: string): Promise<ApiBaseDataResponseObjectType<Array<ApiModelOrderTagInterface>>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<Array<ApiModelOrderTagInterface>>>(
        ApiHttpMethodEnum.get,
        ordersApiUrls.getOrdersTags,
        {
          params: {
            name: searchTerm,
          },
        },
      )
      .then((response) => response.data);
  }

  public async addTag(
    orderId: number,
    name: string,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelOrderTagInterface>> {
    const url = ordersApiUrls.addOrderTag.replace('{orderId}', orderId.toString());

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ApiModelOrderTagInterface>>(ApiHttpMethodEnum.post, url, {
        data: {
          name,
        },
      })
      .then((response) => response.data);
  }

  @Catch()
  public async updateOrderPaymentStatus({
    orderId,
    status,
  }: {
    orderId: number;
    status: string;
  }): Promise<ApiBaseDataResponseObjectType> {
    const url = ordersApiUrls.updateOrderPaymentStatus.replace('{orderId}', orderId.toString());
    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, url, {
        data: {
          status,
        },
      })
      .then((response) => response.data);
  }

  public async zidshipNeedPickup(
    reference_numbers: Array<number>,
  ): Promise<ApiBaseDataResponseObjectType<OrderFirstMilePickupResponseInterface>> {
    const url = zidShipApiUrls.zidshipNeedPickup.replace('{reference_numbers}', reference_numbers.toString());

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<OrderFirstMilePickupResponseInterface>>(ApiHttpMethodEnum.get, url)
      .then((response) => {
        return response.data;
      });
  }

  public async zidshipAssignPickupRequest({
    reference_numbers,
    period,
  }: {
    reference_numbers: Array<number>;
    period: string;
  }): Promise<ApiBaseDataResponseObjectType> {
    const url = zidShipApiUrls.zidshipAssignPickupRequest;

    return this.apiClient
      .request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, url, {
        data: {
          reference_numbers: reference_numbers,
          period: period,
        },
      })
      .then((response) => response.data);
  }

  public async checkIfHasPickupFeature(): Promise<
    ApiBaseDataResponseObjectType<OrderFirstMileHasPickupFeatureInterface>
  > {
    const url = zidShipApiUrls.checkIfHasPickupFeature;

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<OrderFirstMileHasPickupFeatureInterface>>(ApiHttpMethodEnum.get, url)
      .then((response) => {
        return response.data;
      });
  }

  public async getMaxBoxesNumber({
    serviceCode,
    originCity,
    originCountryId,
    destinationCity,
    destinationCountryId,
    destinationCityId,
    originCityId,
  }: {
    serviceCode: serviceLevelsCodeEnum;
    originCity: string;
    originCountryId?: number;
    destinationCity: string;
    destinationCountryId?: number;
    destinationCityId?: number;
    originCityId: number;
  }): Promise<ApiBaseDataResponseObjectType<{ max_pieces: number }>> {
    const url = zidShipApiUrls.maxBoxesNumber
      .replace('{code}', serviceCode)
      .replace('{origin_city}', originCity)
      .replace('{origin_country_id}', String(originCountryId || ''))
      .replace('{destination_city}', destinationCity)
      .replace('{destination_country_id}', String(destinationCountryId || ''))
      .replace('{destination_city_id}', String(destinationCityId || ''))
      .replace('{origin_city_id}', String(originCityId || ''));
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<{ max_pieces: number }>>(ApiHttpMethodEnum.get, url)
      .then((response) => {
        return response.data;
      });
  }

  public async convertGiftCardImageToBase64(
    imageUrl: string,
  ): Promise<ApiBaseDataResponseObjectType<{ imageBase64: string }>> {
    const url = ordersApiUrls.giftImageBase64;
    const response = await this.apiClient.request(ApiHttpMethodEnum.post, url, {
      data: { imageUrl },
    });

    return response.data;
  }

  public async addOrderComment(
    orderId: number,
    comment: string,
  ): Promise<ApiBaseResponseType<ApiModelCustomerPrimaryInterface>> {
    const url = ordersApiUrls.addOrderComment.replace('{orderId}', orderId.toString());

    const response = await this.apiClient.request<ApiBaseResponseType<ApiModelCustomerPrimaryInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: {
          comment,
        },
      },
    );
    return response.data;
  }

  public async setOrderShippingMethod(orderId: number, shippingMethodId: number): Promise<ApiBaseResponseType> {
    const url = ordersApiUrls.setOrderShippingMethod;

    const response = await this.apiClient.request<ApiBaseResponseType>(ApiHttpMethodEnum.post, url, {
      data: {
        order_id: orderId,
        shipping_method_id: shippingMethodId,
      },
    });
    return response.data;
  }

  public async getOrderPII(orderId: number): Promise<ApiBaseResponseType<OrderPIIResponseInterface>> {
    const url = ordersApiUrls.getOrderPII;

    const response = await this.apiClient.request<ApiBaseResponseType<OrderPIIResponseInterface>>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: {
          order_id: orderId,
        },
        cacheable: true,
      },
    );
    return response.data;
  }

  public async getOrdersHighlights(): Promise<ApiBaseResponseType<OrdersApiGetOrdersHighlightsResponseInterface>> {
    const url = ordersApiUrls.getOrdersHighlights;

    const response = await this.apiClient.request<ApiBaseResponseType<OrdersApiGetOrdersHighlightsResponseInterface>>(
      ApiHttpMethodEnum.get,
      url,
    );
    return response.data;
  }
}
